<template>
  <div class="app-modal__box">
    <div class="app-modal__header">
      <h3 class="app-title">
        <i :class="[`mdi mdi-${title.icon} w-6 mr-2`]" v-if="title.icon"></i
        >{{ $t(title.text) }}
      </h3>
    </div>
    <div class="app-modal__body">
      <div v-if="text || name">
        <span v-if="name" class="text-primary mr-1 inline-block font-bold"
          >{{ $t(name) }} -
        </span>
        {{ $t(text) }}
      </div>
      <BaseInput
        :id="input?.text"
        :name="input?.text"
        :label="input?.text"
        @input="inputHandler"
        :value="inputTextValue"
        :error="inputError"
        :errorText="input?.errorText"
        v-if="input"
      ></BaseInput>
    </div>
    <div class="app-modal__footer" v-if="button">
      <BaseButton
        :type="type"
        :symbol="button.symbol"
        :icon="button.icon"
        :label="button.label"
        @click="confirm"
        :disabled="input?.required && inputTextValue === ''"
      ></BaseButton>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  title: {
    text: string
    icon?: string
  }
  text?: string
  name?: string
  type?: 'danger' | 'warning'
  button?: {
    label: string
    icon?: string
    symbol?: string
  }
  input?: {
    text: string
    required: boolean
    errorText: string
  }
}>()

const inputTextValue = ref('')
const inputError = ref(false)

const emit = defineEmits<{
  (e: 'confirm', value?: string): void
  (e: 'close'): void
}>()

function confirm() {
  if (props.input?.required) {
    if (!inputTextValue.value) {
      inputError.value = true
      return
    } else {
      inputError.value = false
      emit('confirm', inputTextValue.value)
      emit('close')
    }
  } else {
    emit('confirm')
    emit('close')
  }
}

function inputHandler($event) {
  inputTextValue.value = $event.target.value
  inputError.value = inputTextValue.value ? false : true
}
</script>
